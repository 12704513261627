<template>
  <div v-if="dialog">
    <v-dialog :value="dialog" max-width="1200" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            {{ $t('title.list_user_ip') }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="_id"
              :headers="headers"
              :footer-props="footerProps"
              :items="listData ? listData : []"
              mobile-breakpoint="0"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
      <loader
      v-if="this.waiting == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </div>
</template>

<script>


export default {
  props: ["darkmode", "dialog", "listData", "dataRequest", "userp"],
  data: () => {
    return {
      dialogListUser: false,
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100, 200]
      },
      item: null,
      selectedUser:[],
      suspendDialog : false,
      waiting:false
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('header.date'), value: 'date' },
        { text: this.$t('header.public_ip'), value: 'public_ip' },

      ];
    },
  },
}
</script>

<style scoped>

</style>
